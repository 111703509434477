<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0">{{ title }}</h4>
        </div>
      </div>
    </div>
    <div class="row mb-4 justify-content-center">
      <div class="col-xl-6">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <div>
                <h5 class="mt-3 mb-1">
                  Usuario:
                  {{ item.userName ? item.userName : 'Sin Informacion' }}
                </h5>
              </div>
              <div class="text-muted">
                <div class="mt-4 mb-0">
                  <div>
                    <p class="mb-1">{{ $t('profile.name') }}:</p>
                    <h5 class="font-size-16">
                      {{ item.name ? item.name : 'Sin Informacion' }}
                    </h5>
                  </div>
                  <div>
                    <p class="mt-4 mb-1">{{ $t('profile.lastName') }}:</p>
                    <h5 class="font-size-16">
                      {{ item.lastName ? item.lastName : 'Sin Informacion' }}
                    </h5>
                  </div>
                  <div class="mt-4 mb-1">
                    <p class="mb-1">{{ $t('profile.email') }}:</p>
                    <h5 class="font-size-16">
                      {{ item.email ? item.email : 'Sin Informacion' }}
                    </h5>
                  </div>
                  <div class="mt-4 mb-1">
                    <p class="mb-1">{{ $t('profile.creationDate') }}:</p>
                    <h5 class="font-size-16">
                      {{
                        item.createdAt
                          ? new Date(item.createdAt).toDateString()
                          : 'Sin Informacion'
                      }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5>Cambiar zona horaria:</h5>
              <div>
                <vs-input
                  border
                  class="shadow-lg mb-2"
                  v-model="item.timeZone"
                />
                <vs-button type="button" @click="saveTimeZone"
                  >GUARDAR</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h5 class="mb-1">{{ $t('profile.updatePassword') }}</h5>
            </div>
            <hr class="my-4" />
            <div class="text-muted">
              <div class="mt-4">
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('profile.newPassword') }}:</p>
                  <vs-input
                    class="shadow-lg"
                    v-model="newPassword"
                    type="password"
                    name="password"
                  />
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('profile.confirmPassword') }}:</p>
                  <vs-input
                    class="shadow-lg"
                    v-model="confirmPassword"
                    type="password"
                    name="password"
                  />
                </div>
                <div class="mt-4 mb-1 text-center">
                  <vs-button
                    id="show-btn"
                    @click="comparePassword"
                    type="button"
                    >{{ $t('profile.updatePassword') }}</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

/**
 * Profile component
 */
export default {
  components: { Layout },
  page: {
    title: 'Perfil',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: this.$t('profile.userProfile'),
      item: {},
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  async created() {
    if (!this.$router.currentRoute.params.id)
      return this.$router.push({ path: '/users' });

    await this.fetchUser(this.$router.currentRoute.params.id);

    this.item = this.user;
  },
  computed: {
    ...mapGetters({
      user: 'user/getCurrentUser',
    }),
  },
  methods: {
    updatePassword() {
      this.$http
        .patch(`users/password/${this.$router.currentRoute.params.id}`, {
          password: this.newPassword,
        })
        .then(({ data }) =>
          Swal.fire({
            icon: 'success',
            title: 'Trabajo realizado correctamente',
            text: data.msg,
          })
        )
        .catch(({ data }) =>
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.msg,
          })
        );
    },
    comparePassword() {
      if (this.newPassword === this.confirmPassword) {
        this.updatePassword();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Las contraseña deben coincidir!',
        });
      }
    },
    async saveTimeZone() {
      const { timeZone, _id, password, ...rest } = this.item;
      console.log(password);
      try {
        const { data } = await this.$http.put(`users/${_id}`, {
          ...rest,
          timeZone,
        });

        if (!data.ok)
          return this.$swal.fire({
            title: 'Error saving time zone',
            icon: 'error',
          });

        this.$swal.fire({ title: 'Time zone saved', icon: 'success' });
      } catch (error) {
        console.log('ERROR SAVIN TIME ZONE', error);
        this.$swal.fire({ title: 'Error saving time zone', icon: 'error' });
      }
    },
    ...mapActions({
      fetchUser: 'user/fetchUser',
    }),
  },
  validations: {
    newPassword: {
      required,
    },
    confirmPassword: {
      required,
    },
  },
};
</script>
